import { css, Global } from '@emotion/react';
import React from 'react';

export const FontIcons = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Mercury-Holidays-Icons';
        src:
          url('/fonts/Mercury-Holidays-Icons/Mercury-Holidays-Icons.eot') format('embedded-opentype'),
          url('/fonts/Mercury-Holidays-Icons/Mercury-Holidays-Icons.ttf') format('truetype'),
          url('/fonts/Mercury-Holidays-Icons/Mercury-Holidays-Icons.woff') format('woff'),
          url('/fonts/Mercury-Holidays-Icons/Mercury-Holidays-Icons.woff2') format('woff'),
          url('/fonts/Mercury-Holidays-Icons/Mercury-Holidays-Icons.svg') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      [class^='mhi-'],
      [class*=' mhi-'] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'Mercury-Holidays-Icons' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .mhi-setting:before {
        content: '\e962';
      }

      .mhi-online-booking:before {
        content: '\e963';
      }

      .mhi-abta-atol:before {
        content: '\e964';
      }

      .mhi-airport-extras:before {
        content: '\e900';
      }

      .mhi-alert:before {
        content: '\e901';
      }

      .mhi-arrival:before {
        content: '\e902';
      }

      .mhi-arrow-right:before {
        content: '\e903';
      }

      .mhi-arrow-up:before {
        content: '\e904';
      }

      .mhi-best-value:before {
        content: '\e905';
      }

      .mhi-boat:before {
        content: '\e906';
      }

      .mhi-booking:before {
        content: '\e907';
      }

      .mhi-brochure:before {
        content: '\e908';
      }

      .mhi-calendar:before {
        content: '\e909';
      }

      .mhi-car:before {
        content: '\e90a';
      }

      .mhi-card:before {
        content: '\e90b';
      }

      .mhi-care:before {
        content: '\e90c';
      }

      .mhi-caret-down:before {
        content: '\e90d';
      }

      .mhi-caret-up:before {
        content: '\e90e';
      }

      .mhi-cheapest:before {
        content: '\e90f';
      }

      .mhi-extensions:before {
        content: '\e910';
      }

      .mhi-map:before {
        content: '\e911';
      }

      .mhi-check-in-bag:before {
        content: '\e912';
      }

      .mhi-check:before {
        content: '\e913';
      }

      .mhi-chevron-down:before {
        content: '\e914';
      }

      .mhi-chevron-left:before {
        content: '\e915';
      }

      .mhi-chevron-right:before {
        content: '\e916';
      }

      .mhi-chevron-up:before {
        content: '\e917';
      }

      .mhi-clear:before {
        content: '\e918';
      }

      .mhi-contact:before {
        content: '\e919';
      }

      .mhi-currency:before {
        content: '\e91a';
      }

      .mhi-departure:before {
        content: '\e91b';
      }

      .mhi-document:before {
        content: '\e91c';
      }

      .mhi-download:before {
        content: '\e91d';
      }

      .mhi-email:before {
        content: '\e91e';
      }

      .mhi-failure:before {
        content: '\e91f';
      }

      .mhi-faq:before {
        content: '\e920';
      }

      .mhi-fastest-time:before {
        content: '\e921';
      }

      .mhi-flight:before {
        content: '\e922';
      }

      .mhi-guarantee:before {
        content: '\e923';
      }

      .mhi-half-star:before {
        content: '\e924';
      }

      .mhi-hand-luggage:before {
        content: '\e925';
      }

      .mhi-holidays:before {
        content: '\e926';
      }

      .mhi-hotel:before {
        content: '\e927';
      }

      .mhi-info:before {
        content: '\e928';
      }

      .mhi-language:before {
        content: '\e929';
      }

      .mhi-lock:before {
        content: '\e92a';
      }

      .mhi-map-pin-outline:before {
        content: '\e92b';
      }

      .mhi-map-pin:before {
        content: '\e92c';
      }

      .mhi-meal:before {
        content: '\e92d';
      }

      .mhi-menu:before {
        content: '\e92e';
      }

      .mhi-minus:before {
        content: '\e92f';
      }

      .mhi-multi-stay:before {
        content: '\e930';
      }

      .mhi-nights:before {
        content: '\e931';
      }

      .mhi-pace:before {
        content: '\e932';
      }

      .mhi-pause:before {
        content: '\e933';
      }

      .mhi-person:before {
        content: '\e934';
      }

      .mhi-photo:before {
        content: '\e935';
      }

      .mhi-plane:before {
        content: '\e936';
      }

      .mhi-play:before {
        content: '\e937';
      }

      .mhi-plus:before {
        content: '\e938';
      }

      .mhi-quote:before {
        content: '\e939';
      }

      .mhi-rainfall:before {
        content: '\e93a';
      }

      .mhi-resort:before {
        content: '\e93b';
      }

      .mhi-room:before {
        content: '\e93c';
      }

      .mhi-search:before {
        content: '\e93d';
      }

      .mhi-star:before {
        content: '\e93e';
      }

      .mhi-stop:before {
        content: '\e93f';
      }

      .mhi-success:before {
        content: '\e940';
      }

      .mhi-sun:before {
        content: '\e941';
      }

      .mhi-telephone:before {
        content: '\e942';
      }

      .mhi-temprature:before {
        content: '\e943';
      }

      .mhi-thumbs-up:before {
        content: '\e944';
      }

      .mhi-tours:before {
        content: '\e945';
      }

      .mhi-travel-insurance:before {
        content: '\e946';
      }

      .mhi-trophy:before {
        content: '\e947';
      }

      .mhi-umbrella:before {
        content: '\e948';
      }

      .mhi-value:before {
        content: '\e949';
      }

      .mhi-visa:before {
        content: '\e94a';
      }

      .mhi-walk:before {
        content: '\e94b';
      }

      .mhi-website:before {
        content: '\e94c';
      }

      .mhi-warning:before {
        content: '\e94d';
      }

      .mhi-world:before {
        content: '\e94e';
      }

      .mhi-about-us:before {
        content: '\e94f';
      }

      .mhi-atol:before {
        content: '\e950';
      }

      .mhi-brochures:before {
        content: '\e951';
      }

      .mhi-check-in:before {
        content: '\e952';
      }

      .mhi-check-out:before {
        content: '\e953';
      }

      .mhi-coach:before {
        content: '\e954';
      }

      .mhi-financial-protection:before {
        content: '\e955';
      }

      .mhi-mobility:before {
        content: '\e956';
      }

      .mhi-size:before {
        content: '\e957';
      }

      .mhi-train:before {
        content: '\e958';
      }

      .mhi-wifi:before {
        content: '\e959';
      }

      .mhi-basket-error:before {
        content: '\e95a';
      }

      .mhi-sea-plane:before {
        content: '\e95b';
      }

      .mhi-speed-boat:before {
        content: '\e95c';
      }

      .mhi-photograph:before {
        content: '\e95d';
      }

      .mhi-search-error:before {
        content: '\e95e';
      }

      .mhi-arrow-left:before {
        content: '\e95f';
      }

      .mhi-arrow-down:before {
        content: '\e960';
      }

      .mhi-eye:before {
        content: '\e961';
      }
    `}
  />
);
